import React, { useRef, useEffect, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import 'slick-carousel/slick/slick.css';

import '../../../../styles/themes/slick-themes.css';
import { get } from 'lodash';
import useStyles from './style';
import { canUseDOM } from '../../../utils/canUseDOM';

import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import { parseToLDJson } from './parseToLDJson';
import '../../../utils/styles/utils.css';
import Picture from '../../../components/atoms/Picture';
import Typography from '../../../components/atoms/Typography';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import RichText from '../../../components/atoms/RichText';

import ButtonMUI from '../../../components/atoms/Button';
import IconWithCircle from '../../../components/atoms/IconWithCircle';
import Box from '../../../components/molecules/Box';
import ReactPlayer from 'react-player';
import { withNamespaces } from 'react-i18next';
import { pushAnaylyticsData } from '../../../../../src/utils/analytics';
import { breakpoints } from '../../../../styles/themes/vad/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from 'react-helmet';
import Grid from '../../../components/molecules/Grid';
import { COMPONENT_NAME } from './locators';
import aspectRatios from '../../../utils/AspectRatios';

const HeroBannerSupergraphic = props => {
  const {
    cta,
    seoEnglishCta,
    supergraphicImage,
    supergraphicImageMobile,
    bgImage,
    bgImageMobile,
    title,
    description,
    enableScrollArrow,
    bgVideo,
    bgVideoMobile,
  } = props.fields;

  const bgVideoVal = get(bgVideo, 'value.href');
  const bgVideoMobileVal = get(bgVideoMobile, 'value.href');
  const schemaLDJson = parseToLDJson(props.fields);
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.lg}px)`);
  const scrollDownRef = useRef(null);
  const heroVideoBannerRef = useRef(null);
  let [videoReady, setVideoReady] = useState(false);
  const analyticsEventCTA = () => {
    const seoCtaText = get(seoEnglishCta, 'value', '');
    const obj = {
      ...analyticsData.pageBodyInteraction.arrowClick,
      ...{
        label: seoCtaText,
      },
    };
    pushAnaylyticsData(obj);
  };

  const isVideo = bgVideoVal || bgVideoMobileVal;

  let videoSource =
    bgVideoMobileVal && isMobile ? bgVideoMobileVal : bgVideoVal;

  useEffect(() => {
    if (canUseDOM) {
      gsap.registerPlugin(ScrollToPlugin);
      if (scrollDownRef && scrollDownRef.current) {
        const scrollDownSvg =
          scrollDownRef && scrollDownRef.current.querySelector('svg');
        gsap
          .timeline({
            repeat: -1,
            ease: 'Power1.easeInOut',
          })
          .fromTo(
            scrollDownSvg,
            {
              y: 30,
              opacity: 0,
            },
            {
              y: 0,
              opacity: 0,
              ease: 'none',
              duration: 0.3,
            }
          )
          .fromTo(
            scrollDownSvg,
            {
              y: 0,
            },
            {
              y: 30,
              ease: 'none',
              duration: 2,
            }
          )
          .fromTo(
            scrollDownSvg,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              ease: 'none',
              duration: 1,
            },
            '-=2'
          )
          .fromTo(
            scrollDownSvg,
            {
              opacity: 1,
            },
            {
              opacity: 0,
              ease: 'none',
              duration: 1,
            },
            '-=1'
          );
      }
    }
  }, []);

  const scrollDown = () => {
    const heroVideoBannerWrapper = heroVideoBannerRef.current;
    if (!heroVideoBannerWrapper) return;

    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Get the position from top of the page to the element
    const offsetTop = heroVideoBannerWrapper.getBoundingClientRect().top + scrollTop;

    // Get height
    const refHeight = heroVideoBannerWrapper.clientHeight;

    // merge to get scrollToPos
    const scrollToPos = offsetTop + refHeight;
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: scrollToPos },
      ease: 'power1.inOut',
    });
  };

  const classes = useStyles();

  const config = {
    youtube: {
      playerVars: {
        disablekb: 1,
        showinfo: 0,
        autohide: 1,
        modestbranding: 1,
      },
    },
  };

  const onProgress = (e) => {
    if (!videoReady && e.played > 0.0001) {
      setVideoReady(true);
    }
  };

  return (
    <div ref={heroVideoBannerRef} className={classes.heroVideoBannerWrapper} data-locator={COMPONENT_NAME}>
      <Helmet>
        <script type="application/ld+json">{`${schemaLDJson}`}</script>
      </Helmet>
      <Box className={'heroSvg'}>
        <Picture
          media={{
            image: supergraphicImage,
            mobileImage: supergraphicImageMobile,
            disableLazyLoad: true,
            fallbackBackgroundNeeded: false,
            aspectRatio: { mobile: '5x4', desktop: '16x8' },
          }}
          isParalax={false}

        />
      </Box>

      <div className={'bannerBackground'}>
        {isVideo && (
          <ReactPlayer
            url={videoSource}
            progressInterval={200}
            playing={true}
            loop
            muted
            controls={false}
            width="100%"
            height={`${videoReady ? '100%' : '0'}`}
            playsinline={true}
            config={config}
            onProgress={onProgress}
            className={'bgImage'}
          />
        )}
        <Picture
          media={{
            image: bgImage,
            mobileImage: bgImageMobile,
            disableLazyLoad: true,
            fallbackBackgroundNeeded: false,
            aspectRatio: aspectRatios['5x4-m'],
            preferredSize: '5x4-m',
          }}
          isParalax={false}
        />
      </div>
      <Grid className={'videoBannerContentWrapper'}>
        {title && title.value && (
          <Typography
            variant="h2"
            component="h2"
            gutterBottom={false}
            className={'heading'}
            wordBreak
            animateSettings={{
              willAnimate: false,
            }}
          >
            <RichText innerHTML={title} />
          </Typography>
        )}

        {description && description.value && (
          <Typography
            variant="body1Regular"
            component="div"
            className={'bannerDescriptionText'}
            gutterBottom={false}
            wordBreak
            animateSettings={{
              willAnimate: false,
            }}
          >
            <RichText innerHTML={description} />
          </Typography>
        )}

        {cta && cta.value && cta.value.text && (
          <div className="videoBannerBtn">
            <ButtonMUI
              field={cta}
              type="button"
              buttonType="form"
              size="medium"
              hasBorder={false}
              className={'videoBannerCTA'}
              onClick={analyticsEventCTA}
            >
              <Typography variant="body2Bold" component="span" color="inherit">
                <Text field={{ value: cta.value.text }} />
              </Typography>
            </ButtonMUI>
          </div>
        )}
      </Grid>
      {enableScrollArrow && enableScrollArrow.value && (
        <Grid className="scrollBtnSection">
          <div className="scrollBtn">
            <IconWithCircle name="arrowroundeddown" className='arrowBtn' onClick={scrollDown} />
          </div>
        </Grid>
      )}
    </div>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(HeroBannerSupergraphic))
);
