import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './style';
import Box from '../../molecules/Box';
import DctSvgIcon from '../Svg';

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const IconWithCircle = ({ name, className, onClick = () => { } }) => {
  const classes = useStyles();
  return (
    <Box component="div" classes={{ root: `${classes.root} ${className}` }} onClick={onClick}>
      <DctSvgIcon name={name} style={{ width: '24px', height: '24px' }} />
    </Box>
  );
};

IconWithCircle.defaultProps = {
  className: '',
};

IconWithCircle.propTypes = propTypes;

export default IconWithCircle;
