export const parseToLDJson = (content) => {
  const output = [
    content?.supergraphicImage?.value.src && {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      'contentUrl': content.supergraphicImage.value.src,
    },
    content?.supergraphicImageMobile?.value.src && {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      'contentUrl': content.supergraphicImageMobile.value.src,
    },
    content?.bgVideo?.value.href && {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      'contentUrl': content.bgVideo.value.href,
    },
    content?.bgVideoMobile?.value.href && {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      'contentUrl': content.bgVideoMobile.value.href,
    },
  ]


  return JSON.stringify([].concat.apply([], output))
}

