import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0,
    ...theme.mixins.right(theme.spacing(3)),
    border: '2px solid white',
    position: 'absolute',
    borderRadius: borderRadius.circle,
    width: theme.spacing(6),
    height: theme.spacing(6),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.right(theme.spacing(12)),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
  },
}));

export default useStyles;
